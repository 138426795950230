/** @format */

import React, { Component } from "react";
import "./index.scss";
import "../../../static/scss/globalComponent.scss";
import Input from "../../../components/InputBox/Input"
import { fetchDauAndMauData } from "../../../services/daumau";
import ReactTable from "./table";
import {  Menu, MenuItem, Paper, withStyles } from "@material-ui/core";
import moment from "moment";
import Snackbar from "../../../components/snackbar/snackbar";
import { config, configDonut, } from "./graphConfig/config";
import BarGraph from "./graph/barGraph";
import ReactToPrint from "react-to-print";
import Modal from "../../../components/modal/modal";
import Pbutton from "../../../components/button/Button";
import { withRouter } from "react-router-dom";
import { CSVLink } from "react-csv";
import Header from "./header";
import { storeIdApiCall } from "../../../services/storeId";
import StoreIdDropDown from "../../../components/StoreIdDropdown";
import Loader from "../../../components/loader/Loader";
// import { apiData } from "./utilization_trucker";
// import { tooltipFormatter } from "../../../lib/graphFunctions";
// import { apiData } from "../TruckUtilisation/utilization_trucker";
import DonutGraph from "./graph/donutGraph";
import { fetchTransportAcceptenceData } from "../../../services/transporteracceptance";
import Filters from "../../../components/filter/location/filter";
import { dateTimeFormatForGraph, getCurrencySymbol, getFromLocalStorage, tooltipFormatter } from "../../../lib/helper";
import { countries } from "../../../fixtures/dummyApi/countries";
import { getCountries } from "../../../services/chartDetailsApi";
import ReactSelect from "../../../components/Select";
import Table from "../../../../src/pages/index/DashboardOverview/table/topNFrame.js"

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  dataHeader: {
    width: "150px",
    fontSize: "10px",
    fontFamily: "Poppins",
    maxWidth: "170px",
    minWidth: "150px",
    textAlign: "center",
    fontWeight: 600,
    color: "#484848",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  data: {
    width: "150px",
    fontSize: "10px",
    fontFamily: "Poppins",
    maxWidth: "170px",
    minWidth: "150px",
    textAlign: "center",
  },
  menuItem: {
    width: "300px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  selectCountryButton: {
    background: "#FFF",
    border: "1px solid #bbb",
    borderRadius: "4px",
    padding: "7px 12px",
    marginLeft: "10px",
  },
});


class DispatchTime extends Component {
  constructor() {
    super();
    // reference of the component
    this.myRef = React.createRef();
    this.state = {
      snackBarMessage: "",
      showSnackbar: false,
      focusedInput: "select a date range",
      focused: false,
      selectedDevice: {
        value: 1,
        label: "Android",
      },
      currencyFilter: {
        label: "Currency filter",
        input: {
          name: "currencyFilter",
          type: "text",
          value: "",
        },
      },
       // curreency state
    anchorEl: null,
    currencyData: localStorage.getItem("currency")
    ? JSON.parse(localStorage.getItem("currency"))
    : window && window._env_ && window._env_.CURRENCY ?  window._env_.CURRENCY : "INR",

    previousCurrencyData: localStorage.getItem("currency")
    ? JSON.parse(localStorage.getItem("currency"))
    : window && window._env_ && window._env_.CURRENCY ?  window._env_.CURRENCY: "INR",
    filterData: [...countries],
    currencyFilter: {
      label: "Currency filter",
      input: {
        name: "currencyFilter",
        type: "text",
        value: "",
      },
    },
      sortByDevice: 0,
      config: config,
      configDonut:configDonut,
      tableColumns: [],
      tableData: [],
      tableLoader: true,
      loader:false,
      ss:"",
      selectData: [{ value: 0, label: "Hour" }],
      chartCategories: [],
      chartSeries: [],
      API_DATA: null,
      isExport: false,
      deviceName: "All Devices",
      isMoreThanThousand: false,
      daysOfWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      isDisplayChart: false,
      noDataText: "Loading Data ...",
      startDate: moment()
        .startOf("day")
        .subtract(0, "M")
        .format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      groupBy: { value: 0, label: "Hour" },
      device: { value: 0, label: "All Devices" },
      storeIdValue: "0",
      storeData: [{ id: 0, name: "--No Data--" }],
      dataLoaded: false,
      VhuWeight: 0,
      topCreatorsTable:[],
      topCreatorsLoader:true,
      VhuVolume: 0,   // currency
      selectedCurrency: window && window._env_ && window._env_.CURRENCY ?  window._env_.CURRENCY : getFromLocalStorage("currency"),
      previousCurrency: window && window._env_ && window._env_.CURRENCY ?  window._env_.CURRENCY : getFromLocalStorage("currency"),
      dataForCurrency:  window && window._env_ && window._env_.CURRENCY ?  window._env_.CURRENCY : getFromLocalStorage("currency"),  
      filterData: [...countries],
      rowsPerPageDispatchTable:5,
      rowsPerPageMainDispatchTable:10,
      driverId:"0",
      source: [
        {label: "Revenue", value: "REVENUE"},
        {label: "No of Post", value: "NO_OF_POST"},
      ],
      selectedsource: {label: "Revenue", value: "REVENUE"},
        // Country filter
    country: "",
    countryCode: "",
    countries: [],
      driverData: [{ 'id': 0, name: 'Fleet Operator' }, {'id':1, name:'Independent'}],
      selected: localStorage.getItem("selectedGroupBy")
      ? JSON.parse(localStorage.getItem("selectedGroupBy"))
      : { value: 0, label: "Hour" },
      noDataFound:false
    }
  }

   // this function calls one time in lifecyle
   componentDidMount() {
    console.log("update1",this.state.selectedsource);
    
    // this.getAllStoreId();
    // let width = window.innerWidth;
    // this.setState({ width });
    // window.addEventListener("resize", this.heightHandler);

    // /**
    //  * @author Jai
    //  * @date 17 Jan, 2023
    //  * @sortBy 4,3,2,1,0 --> quarter, month, week, day, hour respectively send only epocTime - startTime-endTime
    //  * @sortByDevice 1,2,3 --> iOS , Web, Android respectively
    //  */

    this.getData(
      this.state.startDate,
      this.state.endDate,
      this.state.groupBy.value,
      this.state.device.value,
      this.state.currencyData,
      this.state.selectedsource.value,

    );  console.log("update2")
  }

  componentDidMount() {
    console.log("update1");
    console.log("update1",this.state.selectedsource.value);
    this.getData(
      this.state.startDate,
      this.state.endDate,
      this.state.groupBy.value,
      this.state.device.value,
      this.state.currencyData,
      this.state.selectedsource.value,
    );
    // this.intermediate();
  
    console.log("update2");
  }
  
  
 

  getAllStoreId = () => {
    storeIdApiCall()
      .then((res) => {
        this.setState({
          storeData: res.data.data,
          dataLoaded: true,
        });
      })
      .catch((err) => {
        console.log("error store id", err);
        this.setState({
          dataLoaded: false,
        });
      });
  };

  // componentDidMount() {
  // }

  intermediate = () => {
    let symbol = getCurrencySymbol(this.state.currencyData)
    this.getData(
      this.state.date.startDate,
      this.state.date.endDate,
      parseInt(this.state.selected.value),
      this.state.selectedsource.value,
      this.state.currencyData,
      symbol,
      this.state.skip,
      this.state.limit,
    );
  };

  // this function call whenever there is a update in the whole component
  componentDidUpdate(prevProps, prevState) {
    console.log("update");
    console.log("updatestartDate:", this.state.startDate, prevState.startDate , prevState.startDate !== this.state.startDate );
    console.log("updateendDate:", this.state.endDate, prevState.endDate);
    console.log("updategroupBy:", this.state.groupBy.value,prevState.device.value );
    console.log("updatedevice:", this.state.device.value,prevState.storeIdValue);
    // console.log("update byby ",this.state.date.startDate, prevState.date.startDate)
    if (
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate ||
      prevState.groupBy.value !== this.state.groupBy.value ||
      prevState.device.value !== this.state.device.value ||
      prevState.storeIdValue !== this.state.storeIdValue
    ) {
      this.setState(
        {
          config: config,
          tableLoader: true,
          isDisplayChart: false,
          noDataText: "Loading Data ...",
        },
        () => {
          this.getData(
            this.state.startDate,
            this.state.endDate,
            this.state.groupBy.value,
            this.state.device.value, 
            this.state.currencyData,
            this.state.selectedsource.value,
          );
        }
      );
    }
  }
  sortByWeek = (response) => {
    let copyOfResponse = response;
    let series = [];
    let series2 = [];
    this.state.daysOfWeek.map((weekName, index) => {
      let indexOf = response.data.bar_graph.xaxis.categories.indexOf(weekName);
      series.push(response.data.bar_graph.series[0].data[indexOf]);
      return series2.push(response.data.bar_graph.series[1].data[indexOf]);
    });
    copyOfResponse.data.bar_graph.series[0].data = series;
    copyOfResponse.data.bar_graph.series[1].data = series2;
    copyOfResponse.data.bar_graph.xaxis.categories = this.state.daysOfWeek;
    let tableData = series.map((count, index) => {
      return {
        "Day Of Week": this.state.daysOfWeek[index],
        "Total Count": count,
        "Unique Count": series2[index],
      };
    });
    copyOfResponse.data.table = tableData;
    response = copyOfResponse;
    return response;
  };

   //  groupBy manipulating
   //  groupBy manipulating
   selectHandler = (option) => {
    this.setState({ selected: option, loader: true });
    this.getData(this.state.start_timestamp, this.state.end_timestamp,this.state.groupBy.value,this.state.driverId, this.state.currencyData,this.state.selectedsource.value,);
    console.log("22",this.state.start_timestamp, this.state.end_timestamp,this.state.storeIdValue,this.state.driverId,this.state.selectedsource.value,)
  };

  filterCurrency = (e) => {
    this.setState({
      currencyFilter: {
        ...this.state.currencyFilter,
        input: {
          ...this.state.currencyFilter.input,
          value: e.target.value,
        },
      },
    });

    let filterData = countries.filter((item, index) => {
      return (
        item.currencies.findIndex(item => item.code && item.code.toLowerCase().includes(e.target.value.toLowerCase()) )  
       !==
          -1 ||
        item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      );
    });
    
    this.setState({
      filterData,
    });
  };

  // This function call the user-activity api and map over the data and sets the chart configuration
  getData = (start_timestamp, end_timestamp,group_by,driverId,currencyData,selectedsource) => {
    console.log("update3", )
    // this.setState({loader:true});
    fetchTransportAcceptenceData(
      moment(start_timestamp).unix(),
      moment(end_timestamp).unix(),
      group_by,
      driverId,
      currencyData,
      selectedsource
      
    )
      .then((res) => {
        console.log("harshita",res.data.data)
        this.setState({tableData:res.data.data.table
        ,
        // labels:res.data.pie_chart.label, 
        ss:res.data.data.table})
        if(res.status===200){
          this.setState({noDataFound:false});
          this.setState({loader:false});
         }
        let response = res.data;
        console.log("ranjan2",response.data.pie_chart.series)
         const newDonut = {
          ...this.state.configDonut,
          series:response.data.pie_chart.series,
          options:{
            ...this.state.configDonut.options,
            labels:response.data.pie_chart.label
          }
         }

        this.setState({configDonut:newDonut})
        console.log("VHU Weight", response.data)
        this.setState({ config: config });
        let newConfig = { ...this.state.config };
        newConfig.series = response.data.bar_graph.series;
         
        // this.setState({ configDonut: configDonut });
        // let newDonutConfig = {...this.state.configDonut};
        // newDonutConfig.series = response.data.pie_graph.series;

        let format = {
          Hour: "ll H:00",
          Day: "ll",
          Week: "ll",
          "Hour Of Day": "HH:00",
        };
        let xCategories;
        if (this.state.groupBy.label === "Hour") {
          xCategories = response.data.bar_graph.xaxis.categories.map(
            (dateTime) => {
              return moment(dateTime).format(format[this.state.groupBy.label]);
            }
          );
        } else if (
          this.state.groupBy.label === "Day" ||
          this.state.groupBy.label === "Week" ||
          this.state.grconfigDonutoupBy.label === "Hour Of Day"
        ) {
          xCategories = response.data.bar_graph.xaxis.categories.map(
            (dateTime) => {
              return moment(dateTime).format(format[this.state.groupBy.label]);
            }
          );
        } else {
          xCategories = response.data.bar_graph.xaxis.categories;
        }
        let isThousandData = response.data.show_message ? true : false;
        let numberOfDecimal =
          Math.max(...response.data.bar_graph.series[0].data) <= 3 ? 1 : 0;

        newConfig.options = {
          ...newConfig.options,
          xaxis: {
            categories: response.data.bar_graph.xaxis.categories,
            title: {
              text: response.data.bar_graph.xaxis.title,
              style: {
                fontSize: "11px",
                fontWeight: 700,
                // offsetY: 10,
              },
            },
          },
          grid: {
            padding: {
              left: 40,
            },
          },
          labels: xCategories,
          noData: { ...newConfig.options.noData, text: "" },
          yaxis: {
            labels: {
              offsetX: 20,
              formatter: function (val, index) {
                return (val || 0).toFixed(numberOfDecimal);
              },
            },
            title: {
              text: response.data.bar_graph.yaxis.title,
              style: {
                fontSize: "11px",
                fontWeight: 700,
              },
            },
          },
          plotOptions: {
            ...newConfig.plotOptions,
            bar: {
              ...newConfig.plotOptions,
              // columnWidth: "70%",
            },
          },
        };
        // configDonut.options = {
        //   ...configDonut.options,
        //   grid: {
        //     padding: {
        //       left: 40,
        //     },
        //   },
        //   labels: response.data.data.pie_graph.label,
        //   noData: { ...newConfig.options.noData, text: "" },
        //   plotOptions: {
        //     ...newConfig.plotOptions,
        //     Pie: {
        //       ...newConfig.plotOptions,
        //       // columnWidth: "70%",
        //     },
        //   },
        // };

        //  Decreasing the column width if the nummber of the data is less
        // if (response.data.bar_graph.xaxis.categories.length < 6) {
        //   newConfig.options = {
        //     ...newConfig.options,
        //     plotOptions: {
        //       ...newConfig.plotOptions,
        //       bar: {
        //         ...newConfig.plotOptions,
        //         columnWidth: "10%",
        //         dataLabels: {
        //           hideOverflowingLabels: false,
        //         },
        //       },
        //     },
        //   };
        // }
        // if (response.data.bar_graph.xaxis.categories.length >= 20) {
        //   newConfig.options = {
        //     ...newConfig.options,
        //     plotOptions: {
        //       ...newConfig.plotOptions,
        //       bar: {
        //         columnWidth: "100%",
        //         dataLabels: {
        //           hideOverflowingLabels: false,
        //         },
        //       },
        //     },
        //   };
        // }
        // console.log("mmmmmmmmmmmmmmmmmmmmmm",response.data.data.table)
        console.log("ppppppppppppppp",this.state.configDonut)
        this.setState({
          VhuWeight: response.data['VHU Weight'],
          VhuVolume: response.data['Mean VHU Volume'],
          config: newConfig,
          configDonut:newDonut,
          tableData: response.data.table,
          tableLoader: false,
          isMoreThanThousand: isThousandData,
          isDisplayChart: true,
          noDataText: "",
        });
      })
      .catch((error) => {
        this.setState({  configDonut: {
          ...configDonut,
          series:[],
          options: {
            ...configDonut.options,
            xaxis: {
              categories: [],
              // labels: { show: false },
              title: { text: "Duration" },
            },
            labels:[],
          },
        },})
        this.setState({loader:false});
        if (!this.state.startDate) {
          this.setState({
            showSnackbar: true,
            snackBarMessage: "Please select a date range",
          });
        }else if (error.response && error.response.status === 404) {
          this.setState({
            dataLoaded: false,
            showSnackbar: true,
            snackBarMessage: error.response.data.message,
            noDataText: error.response.data.message,
            noDataFound:true
          });}
          // if ( error.response.status === 400) {
          //   console.log("emotion value")
          //   this.setState({
          //     dataLoaded: false,
          //     showSnackbar: true,
          //     snackBarMessage: error.response.data.message,
          //     noDataText: error.response.data.message,
          //     noDataFound:true
          //   });
          // }
           else {
          let message =
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : "Data Not Found For the Given Date Range!";
          this.setState({

            // loader: false,
            tableLoader: false,
            config: {
              ...config,
              options: {
                ...config.options,
                xaxis: {
                  categories: [],
                  // labels: { show: false },
                  title: { text: "Duration" },
                },
                noData: {
                  ...config.options.noData,
                  text: "No data found for the selected date range",
                },
              },
            },
            // tableData: [],
            showSnackbar: true,
            snackBarMessage: message,
            noDataText: "No data for the selected date range",
          });
        }
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
          });
        }, 1000);
        console.log("Api error", error);
      });
  };
  headerChangeHandler = (state, value) => {
    if (state && value) {
      if (state === "groupBy") {
        this.setState({ groupBy: value });
      }
      if (state === "date") {
        this.setState({ startDate: value.startDate, endDate: value.endDate });
      }
      if (state === "device") {
        this.setState({ device: value });
      }
    }
  };

  dropDownSelect = (e) => {
    this.setState({
      storeIdValue: e.target.value,
    });
  };

  driverIdSelect= (e)=>{
    const driverId = e.target.value
    this.setState({
      driverId: e.target.value,
    },)
    this.getData(this.state.startDate, this.state.endDate,this.state.groupBy,driverId, this.state.currencyData,this.state.selectedsource.value,)
  }

  //! Drop down filter for the country name 
  handleFilterData = (value, name) => {

    try {
      if (name === "initialCall") {
        getCountries()
        .then((response) => {
            if (response && response.data && response.data.data && response.data.data.data) {
              this.setState({
                countries: response.data.data.data,
              });
            }
          })
          .catch((error) => {
            console.log("Countries api error", error);
          });
      }
      if (name === "countries") {
        this.setState({
          country: name,
          countryCode: value === null ? "" : value,
        });
      }

      let key =
        name === "countries"
          ? "country"
          : "";
      if (key) {
        const data = this.state[name];
        // this.setState({
        //   [key]: _.result(
        //     _.find(data, function (obj) {
        //       return obj['country_code'] === value;
        //     }),
        //     // "name"
        //     "country_name"
        //   ),
        // });
      }
    } catch (err) {
      console.log("Something went wrong here", err);
    }
  };

    // Date manipulating
    handleDateRange = (startDate, endDate, groupBy, selectedGroupBy) => {
      console.log("Datesssss",startDate,endDate,groupBy,selectedGroupBy)
      this.setState(
        {
          // date: {
            startDate: startDate,
            endDate: endDate,
          // },
          selectData: groupBy,
          selected: selectedGroupBy,
          // loader: true,
        }
      );
    };
    handleClose = () => {
      this.setState({ anchorEl: null });
    };

    getCurrencyData = (obj) => {
      console.log("update5",obj);
       const objCurrency =obj.currencies[0].code
       console.log("update6", objCurrency)
      this.setState({
        currencyData: objCurrency,
      });
      this.getData( this.state.startDate,
        this.state.endDate,
        this.state.groupBy.value,
        this.state.device.value,
        objCurrency,
        this.state.selectedsource.value,);
      this.handleClose();
    };

    handleClick = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    };   

    selectHandlerr = (option) => {
      console.log("oooooooooooooooo",option);
      const vv = option;
      this.setState({
          selectedsource: vv,
      });
      
      // if(this.state.selectedsource.value ==='REVENUE'){
      //   this.setState({
      //     selectedsource:'NO_OF_POST'
      //   })
      // }
      this.getData(
        this.state.startDate,
        this.state.endDate,
        this.state.groupBy.value,
        this.state.device.value, 
        this.state.currencyData,
        vv.value,
      );console.log("oooo0000000000000000000",this.state.selectedsource, "vv", vv )
  }
    
    topCreatorsDropDown = (e) => {
      this.setState({ topource: e, topCreatorsLoader:true },
        this.sourceBytopCreators(
          moment(this.state.startDate).unix(),
          moment(this.state.endDate).unix(),
          this.state.groupBy,
          this.state.selectedCurrency,
          e
        ))       
    }

  render() {
    const { classes } = this.props;
    console.log("bbbbbbbbbbbbbbbbbb",classes)
    return (
      <div>
        <div className="reportWrappers" ref={this.myRef}>
          <div className="head">
            <div
              className="text-grey cursor-pointer globalFontSize"
              onClick={() => this.props.history.push("analytics-overview")}
            >
              <i className="fas fa-angle-left mr-2"></i>Reports
            </div>
            <div className="title">Top Creator</div>
            <div className="d-flex text-grey mt-2 globalFontSize">
              <div className="mr-3 db_ptr">
                <ReactToPrint
                  trigger={() => (
                    <span>
                      <i className="fas fa-print mr-1"></i>Print
                    </span>
                  )}
                  content={() => this.myRef.current}
                />
              </div>
              <div
                className="mr-3 db_ptr"
                onClick={() =>
                  this.setState({ isExport: !this.state.isExport })
                }
              >
                <i className="fas fa-download mr-1"></i>Export
              </div>
            </div>
            <div
              className="moreThanThousand"
              style={{ display: this.state.isMoreThanThousand ? "" : "none" }}
            >
              <div className="iconDiv">
                <span className="iconSpan">
                  {/* <img className='exportImage' src={SvgIcon} /> */}
                </span>
              </div>
              <div className="bannerContent">
                This report shows up to 1,000 results. To see all results, you
                can
                {/* <button className='buttonExport' onClick={this.fetchExportTableData}>
                           <span>export the report</span>
                        </button> */}
              </div>
            </div>
            <div className="d-flex align-items-center mt-2">
            {/* <Header
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              groupBy={this.state.groupBy}
              // storeData={this.state.storeData}
              storeIdValue={this.state.storeIdValue}
              // dropDownSelect={this.dropDownSelect}
              dataLoaded={this.state.dataLoaded}
              setInParentComponent={this.headerChangeHandler}
              groupByselectData={this.state.selectData}
                groupByselectHandler={this.selectHandler}
                groupByselected={this.state.selected}
            /> */}
                 {console.log("lalalalaa",this.state.countries)}
                <Filters
                showCountry={false}
                handleDateChange={this.handleDateRange}
                groupByselectData={this.state.selectData}
                groupByselectHandler={this.selectHandler}
                groupByselected={this.state.selected}
                // showCountry={true}
                country={this.state.country}
                countries={this.state.countries}
                selectHandle={this.handleFilterData}
                hideGroupBy={true}
              ></Filters>
             {/* <div>
          <StoreIdDropDown
          storeData={this.state.driverData}
            storeID={this.state.storeID}
            dropDownSelect={this.driverIdSelect}
            />
          </div> */}
             <div className="d-flex align-items-center justify-content-end">
                  <div className="d-flex align-items-center globalFontSize">
                    Currency:
                    <div
                      style={{
                        marginLeft: "5px",
                        padding: "2px 18px 2px 12px",
                      }}
                      className="card"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={this.handleClick}
                    >
                      {this.state.currencyData}
                    </div>
                    <Menu
                      style={{
                        minHeight: "300px",
                        maxHeight: "300px",
                      }}
                      id="simple-menu"
                      anchorEl={this.state.anchorEl}
                      keepMounted
                      open={Boolean(this.state.anchorEl)}
                      onClose={this.handleClose}
                    >
                      <div className="currency-hover-class">
                        {console.log("muuuuu",this.state.filterData)}
                        <MenuItem>
                          Currency:
                          <Input
                            id=""
                            // options = {this.state.filterData}
                            // value ={this}
                            className="filterCurrency"
                           {...this.state.currencyFilter.input}
                            onChange={(e) => this.filterCurrency(e)}
                          />
                        </MenuItem>
                        {this.state.filterData.map((k, index) => (
                          <MenuItem
                            key={index}
                            className={classes.menuItem}
                            onClick={() => this.getCurrencyData(k)}
                          >
                            <span>
                              <img
                                src={k.flag}
                                height={25}
                                width={25}
                                alt="currency"
                              />
                            </span>
                            <span>^</span>
                            <span className="pl-3 currencySymbol">
                              {k.currencies[0].symbol}
                            </span>
                            <span className="pl-3">{k.name}</span>
                          </MenuItem>
                        ))}
                      </div>
                    </Menu>
                  </div>

                  <div className="d-flex align-items-center justify-content-end">
          <span className="numericValue mr-auto ml-3">
             {/* {this.props.loader ? <RevLoader /> : <></>} */}
          </span>
          {/* {this.props.title === 'Top Creators'? */}
          <>
          <span className="fontweightone text-dark mr-3">
              <span className="globalFontSize">Sort By</span>
          </span>
          <span className="topBrandSelectBox mr-3 bg-white">
                <ReactSelect items={this.state.source} handleChange={this.selectHandlerr} selected={this.state.selectedsource} />
          </span>
          </>
          {/* :''
        } */}
        </div>
            </div>
          </div>
          {/* <Table
              // viewActivity = 'top-creators'
              title = {`Top Creators`}
              table={this.state.topCreatorsTable}
              // loader={this.state.topCreatorsLoader}
              currency={this.state.selectedCurrency}
              sourceChangeHandler={(source) => this.topCreatorsDropDown(source)}
            /> */}
          </div>


          <div className="d-flex mt-5">
            <div className="overviewBody d-flex align-items-center left_card mr-3 bg-white">
            {this.state.loader && <Loader /> }
            {this.state.noDataFound ? (<h5 className="d-flex align-items-center justify-content-center w-100 h-100 text-secondary">No data found</h5>) :
            (
              <DonutGraph config={this.state.configDonut}  />
            )}
  

            {/* <ReactTable
              // rowsPerPage ={true}
              rowsPerPage={this.state.rowsPerPageDispatchTable}
              loader={this.state.tableLoader}
              tableData={this.state.tableData}
              groupBy={this.state.groupBy.value}
            /> */}
            </div>

            <div className="overviewBody charts">
              {this.state.loader && <Loader /> }
              {this.state.noDataFound ?( <h5 className="d-flex align-items-center justify-content-center w-100 h-100 text-secondary">No data found</h5>) : 
              (
              <div
                className="chart"
                style={{ display: this.state.isDisplayChart ? "" : "none" }}
              >
                <BarGraph config={this.state.config} />
              </div>)}
            </div>

          </div>
          <div
            className="body tableWrapper"
            style={{ width: "100%", top: "20px" }}
          >
            <ReactTable
            rowsPerPage={this.state.rowsPerPageMainDispatchTable}
            // loader={this.state.tableLoader}
              tableData={this.state.tableData}
              groupBy={this.state.groupBy.value}
              istableLoder={this.state.loader}
              ss={this.state.ss}
            />
          </div>
          {/* Export Modal will only open when user click on export button then only this modal will to ask for export and cancel */}
          <Modal
            isOpen={this.state.isExport}
            toggle={() => this.setState({ isExport: !this.state.isExport })}
            width={"35%"}
          >
            <div className="col-12 px-0">
              <div className="py-3 reportModal-header pl-3 border-bottom">
                Export your Table Data
              </div>
              <div className="py-3 reportModal-subText pl-3 border-bottom">
                Table will be exported as a CSV (comma separated values).
              </div>
              <div className="py-3 col-12">
                <div className="row justify-content-end">
                  <Pbutton
                    onClick={() =>
                      this.setState({ isExport: !this.state.isExport })
                    }
                    className="reportModal-cancelBtn"
                  >
                    Cancel
                  </Pbutton>
                  <CSVLink
                    onClick={() =>
                      this.setState({ isExport: !this.state.isExport })
                    }
                    data={this.state.tableData}
                    filename={"my-file.csv"}
                    className="reportModal-exportBtn"
                    target="_blank"
                  >
                    Export
                  </CSVLink>
                </div>
              </div>
            </div>
          </Modal>
          <Snackbar
            open={this.state.showSnackbar}
            message={this.state.snackBarMessage}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(DispatchTime));
