import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PaginationJs from "../../../../../components/paginationJs/paginationJs";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import ImportExportIcon from '@material-ui/icons/ImportExport';

//import css
import './index.css'
import { tooltipFormatter } from "../../../../../lib/helper";
import UserProfileModel from "../../../../../components/modal/userProfile";
import { userProfile } from "../../../../../services/chartDetailsApi";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  dataHeader: {
    width: "150px",
    fontSize: "10px",
    fontFamily: "Poppins",
    maxWidth: "170px",
    minWidth: "150px",
    textAlign: "center",
    fontWeight: 600,
    color: "#484848",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  filter_cursor: {
    width: "150px",
    fontSize: "10px",
    fontFamily: "Poppins",
    maxWidth: "170px",
    minWidth: "150px",
    textAlign: "center",
    cursor:"pointer",
    fontWeight: 600,
    color: "#484848",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  data: {
    width: "150px",
    fontSize: "10px",
    fontFamily: "Poppins",
    maxWidth: "170px",
    minWidth: "150px",
    textAlign: "center",
  },
  menuItem: {
    width: "300px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  selectCountryButton: {
    background: "#FFF",
    border: "1px solid #bbb",
    borderRadius: "4px",
    padding: "7px 12px",
    marginLeft: "10px",
  },
});
class index extends Component {
  descriptionElementRef = React.createRef();

  constructor() {
    super();
    this.state = {
      filterWindow: false,
      open:false,
      scroll : "paper",
      loader: true,
      userResponse:[],
    };
  }

  //! Calling the API for getting the user profile data
  getData = (creatorId
    ) => {
      userProfile(creatorId
      )
        .then((response) => {
          this.setState({
            userResponse: response.data.result,
            loader:false,
          });
        })
        .catch((error) => {
          let message =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : "Data Not Found For the Given Date Range!";
          console.log("Api error",message);
            
          this.setState({
            message : message,
            loader:false,
          })
        });
    };

    //! Clicking on the Creator user name dialog box will be open
    handleClickOpen = (scrollType, creatorId) => {
      this.getData(creatorId)
      this.setState({
        open:true, 
        loader:true,
      })
    };
  
    //! Dialog box will close
    handleClose = () => {
      this.setState({
        open:false
      })
    };

  render() {
    const { classes } = this.props;
    return (
      <>
      <div className="table">
        <div className="tableWrapper">
          <Paper className={classes.root + " react-table"}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {this.props.showTable &&
                    this.props.columns.map((k, i) => (
                      i !==0 &&
                      <Tooltip title={k.Header} placement="top">
                        <TableCell 
                        style = { this.props.filterColumnName === k.Header ? {color : "green"} : {}}
                        className={ i !== 1 ? classes.filter_cursor : classes.dataHeader} 
                        key={i}
                        onClick = {i !== 1 ? (e) => this.props.sortType(k.Header) : ""}>
                          {k.Header}{i !== 1 ? <ImportExportIcon/> : ""}
                        </TableCell>
                      </Tooltip>
                    ))}
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                    <TableCell className={classes.dataHeader}>Summary</TableCell>
                      <TableCell className={classes.dataHeader} >
                        {this.props.summary['Number of time subscribed']}
                      </TableCell>
                      <TableCell className={classes.dataHeader} >
                        { tooltipFormatter(this.props.summary['Revenue'], this.props.currencyData, true)}
                      </TableCell>
                      <TableCell className={classes.dataHeader}>
                        {`${(this.props.summary['% Revenue'] || 0).toFixed(2)}%`}
                      </TableCell>                       
                  </TableRow>                
              </TableHead>
              <TableBody>
                {this.props.logsData.map((row, i) => {
                  return (
                    <TableRow
                      key={i}
                      style={
                        i % 2 === 0
                          ? { background: "#F6F6F6" }
                          : { background: "#FFF" }
                      }
                    >
                      {Object.values(row)
                        // .splice(1)
                        .map((k, index) => (
                          index !==0 && 
                          <TableCell
                            className={`${classes.data} ${index === 1 ? 'show__clickable' : ''}`}
                            onClick={ () =>  index === 1 && this.handleClickOpen('paper', row.user_id) }
                            component="th"
                            scope="row"
                            style= {index === 1 ? {cursor:"pointer", color:"blue"} : {}}                            
                          >
                            {this.props.returnDataWithTwoDecsOrInteger(k, index-1)}
                          </TableCell>
                        ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </div>

        {/* paginationWrapper */}
        <div className="paginationWrapper">
          <PaginationJs
            changeActivePage={this.props.changeActivePage}
            activePage={this.props.page}
            totalData={this.props.dataCount}
            dataToShowHandler={this.props.dataToShowHandler}
            dataToShow={this.props.dataToShow}
          />
        </div>     
      </div>

      {/* //! if will click on the creator user name then one dialog box will open */}
      <UserProfileModel 
        creatorRes={this.state.userResponse}
        loader={this.state.loader}
        message = {this.state.message}
        open = {this.state.open}
        handleClose = {this.handleClose}
        descriptionElementRef= {this.descriptionElementRef}
        scroll = {this.state.scroll}/>
      </>



    );
  }
}

export default withStyles(styles)(index);
