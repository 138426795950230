import React, { Component } from "react";
import Link from "../../../../components/Link/Link";
import RevLoader from "../../../../components/loader/RevLoader";
import ApexCharts from "react-apexcharts";
import getSymbolFromCurrency from "currency-symbol-map";
import ReactSelect from '../../../../components/Select';
import '../../Overview.scss'
/**
 * @Author Jai
 * @Date 14 July, 2021
 * @Description Created this graph page for getting the status of Total Earning and we are using this page on Analytics Overview.
 */

class TotalEarningOverview extends Component {
  state = {
    source: [
      {label: "All", value: "ALL"},
      {label: "Post Purchase", value: "POST_PURCHASE"},
      {label: "Subscription", value: "SUBSCRIPTION"},
      {label: "Tip", value: "TIP"},
      {label: "Paid Chat", value: "PAID_CHAT"},
      {label: "Video Call", value: "VIDEO_CALL"},
      {label: "ShoutOut", value: "VIDEO_SHOUTOUT"},
      {label: "Live Streams", value: "LIVE_STREAM"}
    ],
    selectedsource: {label: "All", value: "ALL"}
  }

  selectHandler = (option) => {
    this.setState({
        selectedsource: option,
    }, () => this.props.sourceChangeHandler(option.value));
}
  
  render() {
    return (
      <div className="card">
        <div className="spaceBetween mt-3 ml-3 mr-3">
          <span className="label globalFontSize fontweightone">
            Total Earning
          </span>
          <span className="link globalFontSize fontweightone">
            <Link url={`total-earning`}>View Activity</Link>
          </span>
        </div>
        <div className="spaceBetween ml-3 mr-3">
          <span className="numericValue globalFontSize">
            {this.props.loader ? (
              <RevLoader />
            ) : this.props.earningCount ? (
              `${getSymbolFromCurrency(this.props.currency)} ${
                this.props.earningCount
              }`
            ) : (
              `${getSymbolFromCurrency(this.props.currency)} ` + 0
              // 0
            )}
          </span>
            {/* <div className="spaceBetween ml-3 mr-3">
              <span className="fontweightone" style={{alignSelf: "center", color: "#000000"}}>
                <span className="globalFontSize">Sort By</span>
              </span> */}
              <span className="numericValue topBrandSelectBox">
                <ReactSelect items={this.state.source} handleChange={this.selectHandler} selected={this.state.selectedsource} />
              </span>
            {/* </div> */}
        </div>


        <div className="chart">
          <ApexCharts
            options={this.props.config.options}
            series={this.props.config.series}
            type="area"
            height={350}
          />
        </div>
      </div>
    );
  }
}

export default TotalEarningOverview;
