/** @format */

let config = {
    series: [],
    options: {
       chart: {
          height: 400,
          type: "line",
       },
       plotOptions: {
          bar: {
             horizontal: false,
             columnWidth: "70%",
             rangeBarOverlap: false,
             dataLabels: {
                position: "top",
                hideOverflowingLabels: false,
                // orientation: 'vertical'     // Positioning the dataLabels using the orientation method
             },
          },
       },
       dataLabels: {
          horizontal: true,
          enabled: false,
          style: {
             colors: ["#333"],
          },
          offsetY: -40,
          hideOverflowingLabels: false,
       },
       noData: {
          text: "",
          align: "center",
          verticalAlign: "middle",
          style: {
             color: "#212121",
             fontSize: "18px",
             fontFamily: "robo",
          },
       },
       xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
             show: false,
          },
          axisTicks: {
             show: false,
          },
          

          labels: {
            style: {
               fontSize: '11px'
           },
          },
          // crosshairs: {
          //    fill: {
          //       type: "gradient",
          //       gradient: {
          //          colorFrom: "#D8E3F0",
          //          colorTo: "#BED1E6",
          //          stops: [0, 100],
          //          opacityFrom: 0.4,
          //          opacityTo: 0.5,
          //       },
          //    },
          // },
 
          tooltip: {
             enabled: false,
          },
       },
       // fill: {
       //    type: 'gradient',
       //    gradient: {
       //      shadeIntensity: 1,
       //      inverseColors: false,
       //      opacityFrom: 0.5,
       //      opacityTo: 0,
       //      stops: [0, 90, 180]
       //    },
       //  },
       grid: {
          padding: {
             left: 40,
          },
          row: {
             colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
             opacity: 0.5,
          },
       },
       yaxis: {
          // tickAmount: 10,
          title: {
             text: "Total Number Of sales",
             offsetX: 0,
             offsetY: 120,
          },
          labels: {
             formatter: (value) => {
                if (typeof value === "number") {
                   return Number((value || 0).toFixed(2));
                }
                return value;
             }
          },
       },
       tooltip: {
          onDatasetHover: {
             highlightDataSeries: true,
          },
          followCursor: true,
       },
       legend: {
          onItemClick: {
             toggleDataSeries: true
         },
         onItemHover: {
             highlightDataSeries: false
         },
          position: "top",
          horizontalAlign: "center",
       },
       title: {
          text: "",
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
             position: "top",
             color: "#444",
          },
       },
    },
 };
 let configDonut = {
   // series: [45,67,89,34,43, 33 , 44, 65,67],
   series:[],
   options: {
      chart: {
         height: 400,
         type: "donut",
      },
      labels:[],
      colors: ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF','#000000','#800080', '#008000', '#FFC0CB','#00FFFF', '#00CED1'],
      plotOptions: {
         pie: {
            horizontal: false,
            columnWidth: "70%",
            rangeBarOverlap: false,
            dataLabels: {
               position: "top",
               hideOverflowingLabels: false,
               formatter: function (val, opts) {
                  return opts.w.config.labels[opts.seriesIndex];
                },
                style: {
                  colors: ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF','#ffff', '#0000'],
                },
               // orientation: 'vertical'     // Positioning the dataLabels using the orientation method
            },

            donut:{
               labels:{
                  show:true,
                   total: {
              show: true,
              formatter: function (w) {
                return w.globals.seriesTotals.reduce(function (a, b) {
                  return a + b;
                }, 0).toFixed(2);
              },
            },
               }
               
            }
         },
      },
      dataLabels: {
         horizontal: true,
         enabled: true,
         style: {
            colors: ["#fff"],
         },
         offsetY: -40,
         hideOverflowingLabels: false,
      },
      noData: {
         text: "",
         align: "center",
         verticalAlign: "middle",
         style: {
            color: "#212121",
            fontSize: "18px",
            fontFamily: "robo",
         },
      },
      xaxis: {
         categories: [],
         position: "bottom",
         axisBorder: {
            show: false,
         },
         axisTicks: {
            show: false,
         },
         

         labels: {
           style: {
              fontSize: '30px'
          },
          
         },
         // crosshairs: {
         //    fill: {
         //       type: "gradient",
         //       gradient: {
         //          colorFrom: "#D8E3F0",
         //          colorTo: "#BED1E6",
         //          stops: [0, 100],
         //          opacityFrom: 0.4,
         //          opacityTo: 0.5,
         //       },
         //    },
         // },

         tooltip: {
            enabled: false,
         },
      },
      // fill: {
      //    type: 'gradient',
      //    gradient: {
      //      shadeIntensity: 1,
      //      inverseColors: false,
      //      opacityFrom: 0.5,
      //      opacityTo: 0,
      //      stops: [0, 90, 180]
      //    },
      //  },
      grid: {
         padding: {
            left: 40,
         },
         row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
         },
      },
      yaxis: {
         // tickAmount: 10,
         title: {
            text: "Total Number Of sales",
            offsetX: 0,
            offsetY: 120,
         },
         labels: {
            formatter: (value) => {
               if (typeof value === "number") {
                  return Number((value || 0).toFixed(2));
               }
               return value;
            }
         },
      },
      tooltip: {
         onDatasetHover: {
            highlightDataSeries: true,
         },
         followCursor: true,
      },
      legend: {
         onItemClick: {
            toggleDataSeries: true
        },
        onItemHover: {
            highlightDataSeries: false
        },
         position: "right",
         horizontalAlign: "center",
      },
      title: {
         text: "",
         floating: true,
         offsetY: 330,
         align: "center",
         style: {
            position: "top",
            color: "#444",
         },
      },
   },
};
 
 export { config,configDonut };