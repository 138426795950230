import React from "react";
import DateRangePicker from "../../DatePicker";
import Select from "../../AntSelect";
import ReactSelect from "../../reactSelect/reactSelect";
/**
 * @Author Jai
 * @Date 16 July, 2021
 * @Description Created this filter page for selecting the Date Range and GroupBy and we are using this page for Detail Charts.
 */

const Filter = (props) => {
  return (
    <div className="d-flex">

      {props.showCountry ?
      <div className="d-flex align-items-center mr-3">
        <label className="mr-2 globalFontSize mb-0">Country:</label>

        <Select
          handleSelectChange={(id, key) => props.selectHandle(id, "countries")}
          data={props.countries}
          type="countries"
          selected={props.country || "ALL"}
          placeholder="select country"
        ></Select>
      </div>
      :""
      }


      <div className=" d-flex align-items-center">
        <div className="mr-2 globalFontSize">Date Range:</div>
        <DateRangePicker
          getGroupBy={true}
          handleDateChange={props.handleDateChange}
        />
      </div>
        {props.hideGroupBy ? "" :
          <div className="d-flex align-items-center">
            <div className="mr-2 globalFontSize">Group By:</div>
            <ReactSelect
              className={false}
              city={props.groupByselectData}
              change={props.groupByselectHandler}
              selected={props.groupByselected}
            />
          </div>      
        
      
      }

      {/* <div className="col-2">
        <label className="">City</label>
        <Select
          handleSelectChange={(id, key) => props.selectHandle(id, "cities")}
          data={props.cities}
          type="gread"
          placeholder="select city"
          selected={props.city}
        ></Select>
      </div> */}
      {/* <div className="col-2">
        <label className="">Zone</label>
        <Select
          handleSelectChange={(id, key) => props.selectHandle(id, "zones")}
          data={props.zones}
          type="section"
          placeholder="select zone"
          selected={props.zone}
        ></Select>
      </div> */}
    </div>
  );
};

export default Filter;
