/** @format */

import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class userBarGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
      sortByDevice: 3,
      redirect: false,
    };
  }

  render() {
    console.log("pppppppp2",this.props.config)
    return (
      <div className="overviewBody pt-2">
        <ReactApexChart
          options={this.props.config.options}
          series={this.props.config.series}
          type="bar"
          height={340}
        />
      </div>
    );
  }
}

export default userBarGraph;
