import React, { Component } from "react";
import {  Menu, MenuItem, Paper, withStyles } from "@material-ui/core";
import Input from "../../../../components/InputBox/Input";

import {
  getCountries,
  userSignup
} from "../../../../services/chartDetailsApi";

import moment from "moment";
import Snackbar from "../../../../components/snackbar/snackbar";
import BarGraph from "./graph/barGraph";
import ReactToPrint from "react-to-print";
import Modal from "../../../../components/modal/modal";
import Pbutton from "../../../../components/button/Button";
import { withRouter } from "react-router-dom";
import { CSVLink } from "react-csv";
import ReactSelect from "../../../../components/reactSelect/reactSelect";
import TableComponent from "./table/index";
import { countries } from "../../../../fixtures/dummyApi/countries";

// css file
import "../../Overview.scss";
import "../index.css";
import "../../../../static/scss/globalComponent.scss";

import _ from "lodash";
import Filters from "../../../../components/filter/location/filter";
import { dateTimeFormatForGraph, getCurrencySymbol, groupFormat, setInLocalStorage, tooltipFormatter } from "../../../../lib/helper";

const selectCountry = [{label: 'All', value: ''}]
const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  dataHeader: {
    width: "150px",
    fontSize: "10px",
    fontFamily: "Poppins",
    maxWidth: "170px",
    minWidth: "150px",
    textAlign: "center",
    fontWeight: 600,
    color: "#484848",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  data: {
    width: "150px",
    fontSize: "10px",
    fontFamily: "Poppins",
    maxWidth: "170px",
    minWidth: "150px",
    textAlign: "center",
  },
  menuItem: {
    width: "300px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  selectCountryButton: {
    background: "#FFF",
    border: "1px solid #bbb",
    borderRadius: "4px",
    padding: "7px 12px",
    marginLeft: "10px",
  },
});

class UserSignUp extends Component {
  myRef = React.createRef();
  state = {
    snackBarMessage: "",
    showSnackbar: false,
    isExport: false,
    vehicle_type_id: 0,
    isMoreThanThousand: false,
    noDataText: "Loading Data ...",


    loader: true, //loader state
    noData: false,
    dataLoaded: false,

    // userStatus: [
    //   {label: "All", value: "ALL"},
    //   {label: "Active", value: "ACTIVE"},
    //   {label: "Inactive", value: "INACTIVE"},
    // ],
    // selecteduserStatus: {label: "All", value: "ALL"},
  

    userType: [
      {label: "All", value: 0},
      {label: "User", value: 1},
      {label: "Creator", value: 2},
    ],
    selectedUserType: {label: "All", value: 0},

    // curreency state
    currencyData: localStorage.getItem("currency")
    ? JSON.parse(localStorage.getItem("currency"))
    : "INR", 

    // start and end date
    date: {
      startDate:
        localStorage.getItem("startDate") != null
          ? localStorage.getItem("startDate")
          : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      endDate:
        localStorage.getItem("endDate") != null
          ? localStorage.getItem("endDate")
          : moment().format("YYYY-MM-DD HH:mm:ss"),
    },

    // set group by value and list
    selected: localStorage.getItem("selectedGroupBy")
      ? JSON.parse(localStorage.getItem("selectedGroupBy"))
      : { value: 0, label: "Hour" },
    selectData: [{ value: 0, label: "Hour" }],

    exportData: [],
    logsData: [], //table data
    columns: [], //table columns
    dataCount:0,

    skip: 0, //starting point
    limit: 10, //ending point
    page: 1, //pagination page
    dataToShow: 10, //50 or 100 data to show
    showTable: false,

    summary:'',

    // Country filter
    country: "",
    selectCountry: selectCountry[0],
    countryCode: "",
    countries: [],

    filterColumnName : "",
    sortingType: "ASC",
  };

  /**
   * @Author Jai
   * @Date 23 July, 2021
   * @Description Created a User Signup Chart and added a DateRange, GroupBy, Currency and Source filter
   */

  componentDidMount() {
    this.handleFilterData("", "initialCall");
    this.intermediate();
  }

  // this function call whenever there is a update in the whole component
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.date.startDate       !== prevState.date.startDate||
      this.state.date.endDate         !==  prevState.date.endDate ||

      this.state.selectedUserType.value !==  prevState.selectedUserType.value ||
      this.state.filterColumnName     !== prevState.filterColumnName ||
      this.state.sortingType          !== prevState.sortingType ||

      this.state.currencyData         !== prevState.currencyData ||
      this.state.countryCode            !== prevState.countryCode ||
      this.state.selected.value       !== prevState.selected.value ||

      this.state.page                 !== prevState.page ||
      this.state.dataToShow           !== prevState.dataToShow      
    ) {
      this.setState({
        loader:true,
        noDataText: "Loading Data ...",
      });
      this.intermediate();
    }
  }

  intermediate = () => {
    this.getData(
      this.state.date.startDate,
      this.state.date.endDate,
      parseInt(this.state.selected.value),
      this.state.selectedUserType.value,
      this.state.countryCode,
      this.state.filterColumnName,
      this.state.sortingType,
      this.state.skip,
      this.state.limit,
    );
  };

  getData = (
    start_timestamp,
    end_timestamp,
    groupBy,
    userType,
    countryCode,
    columnName,
    sortType,
    skip,
    limit,    
  ) => {
    userSignup(
      moment(start_timestamp).unix(),
      moment(end_timestamp).unix(),
      groupBy || 0,
      userType,
      countryCode,
      columnName,
      sortType,
      skip,
      limit,      
    )
      .then((response) => {
        let xCategories = dateTimeFormatForGraph(
          this.state.selected.label || "Hour",
          response.data.graph.xaxis.categories
        );

        //graph configuration
        let graphData = {
          series: response.data.graph.series,
          options: {
            title: {
              text: "",
              align: "left",
              style: { fontSize: "18px", fontWeight: 500 },
            },
            dataLabels: { enabled: false },
            chart: {
              toolbar : {
                show:false
              },
              height: 350,
              type: 'line'
            },

            grid: {
              // providing padding in the graph
              padding: {
                left: 50,}
              },
            xaxis: {
              type: "category",
              categories: xCategories,
              title: {
                text: response.data.graph.xaxis.title,
                // text: 'Users Count',
                style: {
                  fontSize: "11px",
                  fontWeight: 700,
                  paddingRight: "20px",
                },
              },
              tickPlacement: "on",
              tooltip: {
                enabled: false,
              },
            },

            legend: {
              position: "top",
            },
            yaxis: {
              title: {
                text: response.data.graph.yaxis.title,
                style: {
                  fontSize: "11px",
                  fontWeight: 700,
                  paddingRight: "20px",
                },
              },
              opposite: false,
              labels: {
                offsetX: 15,
                show: true,
                style: { fontSize: "11px" },
                formatter: (val, index) => {
                  return tooltipFormatter(val, this.state.currencyData, false);
                },
              },
            },
            tooltip: {
              enabled: true,
              followCursor: true,
              y: {
                formatter: (val, series) => {
                  return tooltipFormatter(val, this.state.currencyData, false);
                },

                title: {
                  formatter: (seriesName) => seriesName,
                },
              },
            },
          },
        };

        // table data
        let columns = Object.keys(response.data.data[0]); // column name from api
        columns[0] = this.state.selected.label;

        let remData = columns.includes('userTypeCode') ? columns.splice([columns.indexOf('userTypeCode')],1) : ''

        // if (this.state.selectedUserType.value === 0 ){
        //   let count = columns.includes('count') ? columns[columns.indexOf('count')] = 'User Count' : ''
        //   let Creator_count = columns.includes('creator_count') ? columns[columns.indexOf('creator_count')] = 'Creator Count' : ''
        // }
        // else if (this.state.selectedUserType.value === 1){
        //   let count = columns.includes('count') ? columns[columns.indexOf('count')] = 'User Count' : ''
        //   let remData = columns.includes('creator_count') ? columns.splice([columns.indexOf('creator_count')],1) : ''

        // }
        // else if (this.state.selectedUserType.value === 2){
        //   let Creator_count = columns.includes('creator_count') ? columns[columns.indexOf('creator_count')] = 'Creator Count' : ''
        //   let remData = columns.includes('count') ? columns.splice([columns.indexOf('count')],1) : ''
  
        //   }        
        
        let cold = columns.map((item, index) => {
          // modified column name according to table structure
          return {
            Header: item[0].toUpperCase() + item.slice(1),
            accessor: (data) => {
              if (
                item.toLowerCase().includes("date") &&
                this.state.selected.value < 1
              )
                return moment(Object.values(data)[index]).format(
                  "DD-MMMM-YYYY LT"
                );
              else {
                let type = typeof Object.values(data)[index];
                if (type === "number")
                  return (
                    Object.values(data)[index] &&
                    (Object.values(data)[index] || 0).toFixed(2)
                  );
                else return Object.values(data)[index];
              }
            },
            id: item,
            show: true,
            width: 150,
          };
        });


        response.data.data.map((res) => {
          if (this.state.selectedUserType.value === 1){
            delete res.creator_count
            delete res.userTypeCode
            return res
          }
          else if (this.state.selectedUserType.value === 2){
            delete res.count
            delete res.userTypeCode
            return res
          }
          else if(this.state.selectedUserType.value === 0){
            delete res.userTypeCode
          }
        })

        let exportData = response.data.data.map((data=>{
          let tempData = {...data}
          tempData.dateTime = this.state.selected.value ===7 ? tempData.dateTime : moment(tempData.dateTime).format(groupFormat[this.state.selected.label])
          return tempData
        }))

        this.setState({
          showTable: true,
          logsData :response.data.data, // table data
          columns : cold, // table column name
          dataCount:response.data.count,
          loader:false,
          exportData,
          dataLoaded: true,
          summary: response.data.summary,
          graphData, // chart data
          noDataText: "",
          previousCurrencyData: this.state.currencyData
        });
      })
      .catch((error) => {
        if (!this.state.date.startDate) {
          this.setState({
            showSnackbar: true,
            snackBarMessage: "Please select a date range",
          });
        } else {
          let message =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : "Data Not Found For the Given Date Range!";
          this.setState({
            dataLoaded: false,
            showTable: false,
            loader: false,
            showSnackbar: true,
            snackBarMessage: message,
            noDataText: "No data for the selected date range",
            currencyData: this.state.previousCurrencyData
          });
        }
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
          });
        }, 1000);
        console.log("Api error", error);
      });
  };
  // ========= End of getData function ============ //


  //! sorting the column ASE or DESC order based on column name
  columnFilter = (header) => {
    let type =  this.state.sortingType === "DESC" ? "ASC" : "DESC"
      this.setState({
        sortingType : type,
        filterColumnName : header
      })
  }

  //! drop down filter All, user and creator baseed on filter weill get cloumn and graph data
  userTypeSelectHandler = (option) => {
    this.setState({
      selectedUserType: option,
      filterColumnName: option.label === "All" ?  "" : option.label+' Signup'
    });
  };
 

  // Date manipulating
  handleDateRange = (startDate, endDate, groupBy, selectedGroupBy) => {
    this.setState(
      {
        date: {
          startDate: startDate,
          endDate: endDate,
        },
        selectData: groupBy,
        selected: selectedGroupBy,
        loader: true,
      }
    );
  };

  // country filter
  handleFilterData = (value, name) => {

    try {
      if (name === "initialCall") {
        getCountries()
        .then((response) => {
            if (response && response.data && response.data.data && response.data.data.data) {
              this.setState({
                countries: response.data.data.data,
              });
            }
          })
          .catch((error) => {
            console.log("Countries api error", error);
          });
      }
      if (name === "countries") {
        this.setState({
          country: name,
          countryCode: value === null ? "" : value,
        });
      }

      let key =
        name === "countries"
          ? "country"
          : "";
      if (key) {
        const data = this.state[name];
        this.setState({
          [key]: _.result(
            _.find(data, function (obj) {
              return obj['country_code'] === value;
            }),
            // "name"
            "country_name"
          ),
        });
      }
    } catch (err) {
      console.log("Something went wrong here", err);
    }
  };


  //  groupBy manipulating
  selectHandler = (option) => {
    this.setState({ selected: option, loader: true });
  };


  returnDataWithTwoDecsOrInteger = (n, index) => {
    let tableIndex = [2]
    if (Number(n) === n && n % 1 === 0) {
      return tableIndex.includes(index) ? tooltipFormatter(n, this.state.currencyData, false) : n;
    } else if (Number(n) === n && n % 1 !== 0) {
      return tableIndex.includes(index) ? tooltipFormatter(parseFloat((n || 0).toFixed(2)), this.state.currencyData, false ) : parseFloat((n || 0).toFixed(2));
    } else if (String(n)) {
      return n;
    }
  };

  // change pagination page  respectively set SKIP and LIMIT
  changeActivePage = (page) => {
    let state = { ...this.state };

    state.skip = page * state.dataToShow - state.dataToShow;
    // state.limit = page * state.dataToShow;
    state.limit = state.dataToShow;

    state.page = page;
    state.loader = true;
    this.setState(state)
  }

  //set data to show and respectively set SKIP and LIMIT  
  dataToShowHandler = (newVal) => {
    let stateCopy = { ...this.state };

    stateCopy.page = 1;
    stateCopy.skip = 0
    stateCopy.limit = stateCopy.page * newVal;
    stateCopy.dataToShow = newVal;
    stateCopy.loader = true;
    this.setState(stateCopy)
  }

  //! If table have any date column so will convert the date format
  tableDateRender = (date) => {
    let format = {
      Hour: "ll H:00",
      Day: "ll",
      Week: "ll",
      "Hour Of Day": "HH:00",
      Year: "ll",
    };
    if (Object.keys(format).includes(this.state.selected.label)) {
      return moment(date).format(format[this.state.selected.label]);
    } else {
      return date;
    }
  };  

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className="containerDiv" ref={this.myRef}>
          <div className="">
            <div
              className="hoverPathClass globalFontSize"
              onClick={() =>
                this.props.history.push("analytics-overview")
              }
            >
              <i className="fas fa-angle-left mr-2"></i>Reports
            </div>
            <div className="title">User Signup</div>
            <div className="d-flex text-grey mt-2" style={{ marginBottom: "" }}>
              <div className="mr-3 db_ptr changeCurssor">
                <ReactToPrint
                  trigger={() => (
                    <span>
                      <i className="fas fa-print mr-1"></i>Print
                    </span>
                  )}
                  content={() => this.myRef.current}
                />
              </div>
              <div
                className="mr-3 db_ptr changeCurssor"
                onClick={() =>
                  this.setState({ isExport: !this.state.isExport })
                }
              >
                <i className="fas fa-download mr-1"></i>Export
              </div>
            </div>
            <div
              className="moreThanThousand"
              style={{ display: this.state.isMoreThanThousand ? "" : "none" }}
            >
              <div className="iconDiv">
                <span className="iconSpan"></span>
              </div>
              <div className="bannerContent">
                This report shows up to 1,000 results. To see all results, you
                can
              </div>
            </div>
            <div className="d-flex align-items-center mt-2">
              <Filters
                // showCountry={false}
                handleDateChange={this.handleDateRange}
                groupByselectData={this.state.selectData}
                groupByselectHandler={this.selectHandler}
                groupByselected={this.state.selected}
                // selected={this.state.selectCountry}
                selected={'all'}
                showCountry={true}
                country={this.state.country}
                countries={this.state.countries}
                selectHandle={this.handleFilterData}                
              ></Filters>
            {/* </div> */}

              <div className="d-flex align-items-center justify-content-end mt-2 ml-auto">
                <div className="d-flex justify-content-end align-items-center ml-3">
                  <span className="mr-2 globalFontSize">User Type:</span>

                  <div className="mr-2 globalFontSize">
                    <ReactSelect
                      className={"custom-z-index"}
                      city={this.state.userType}
                      change={this.userTypeSelectHandler}
                      selected={this.state.selectedUserType}
                    />
                  </div>
                </div>
            </div>
            </div>
          </div>
          <div
            className="signUpActiveBarGraph d-flex align-items-center justify-content-center"
            style={{ marginTop: "20px" }}
          >
            {this.state.loader ? (
              <h4 style={{ color: "grey" }}>Loading data...</h4>
            ) : this.state.dataLoaded ? (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <BarGraph 
                  config={this.state.graphData}
                  summary={this.state.summary} 
                  userType={this.state.selectedUserType.label}
                  // userStatus= {this.state.selecteduserStatus.label}
                  />

              </div>
            ) : (
              <h4 style={{ color: "grey" }}>No data found</h4>
            )}
          </div>

          <div className="d-flex justify-content-center" 
            style={{
              marginTop: "50px",
            }}          
          >
            {this.state.loader ? (
              <div className="no-data-loader">
                  <h4 style={{ color: "grey" }}>Loading data...</h4>
              </div>
            ) : this.state.dataLoaded ? (

              <TableComponent 
                loader={this.state.loader}
                showTable={this.state.showTable}
                columns={this.state.columns}
                logsData={this.state.logsData}
                
                dateRender={(data) => this.tableDateRender(data)}
                returnDataWithTwoDecsOrInteger={(data, index) =>
                  this.returnDataWithTwoDecsOrInteger(data, index)
                }
                
                // Pagination 
                dataCount={this.state.dataCount}
                page={this.state.page}
                dataToShow={this.state.dataToShow}
                changeActivePage={this.changeActivePage}
                dataToShowHandler={this.dataToShowHandler}

                sortType ={this.columnFilter}
                filterColumnName={this.state.filterColumnName}
              />

             ) : (
              <div className="no-data-loader">
                <h4 style={{ color: "grey" }}>No data found</h4>
              </div>
            )}
          </div>

          {/* Export Modal will only open when user click on export button then only this modal will to ask for export and cancel */}
          <Modal
            isOpen={this.state.isExport}
            toggle={() => this.setState({ isExport: !this.state.isExport })}
            width={"35%"}
          >
            <div className="col-12 px-0">
              <div className="py-3 reportModal-header pl-3 border-bottom">
                Export your Table Data
              </div>
              <div className="py-3 reportModal-subText pl-3 border-bottom">
                Table will be exported as a CSV (comma separated values).
              </div>
              <div className="py-3 col-12">
                <div className="d-flex justify-content-end">
                  <Pbutton
                    onClick={() =>
                      this.setState({ isExport: !this.state.isExport })
                    }
                    className="reportModal-cancelBtn"
                  >
                    Cancel
                  </Pbutton>
                  <CSVLink
                    onClick={() =>
                      this.setState({ isExport: !this.state.isExport })
                    }
                    data={this.state.exportData}
                    filename={"user-signup.csv"}
                    className="reportModal-exportBtn"
                    target="_blank"
                  >
                    Export
                  </CSVLink>
                </div>
              </div>
            </div>
          </Modal>
          <Snackbar
            open={this.state.showSnackbar}
            message={this.state.snackBarMessage}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(UserSignUp));
